import styled from "@emotion/styled";
import { useSelected } from "slate-react";

import { ElementRendererProps } from "./paragraph";
import { useEditorContext } from "../hooks";

const PlaceholderWrapper = styled.span<{ isLink?: boolean }>`
  display: inline-block;
  margin: 0 0.2rem;
  padding: 0.3rem 0.5rem;
  background: ${(props) => props.theme.scheme.grey.r25};
  color: ${(props) =>
    props.isLink ? props.theme.scheme.blue.r100 : "inherit"};
  border-radius: 0.2rem;
  text-decoration: ${(props) => (props.isLink ? "underline" : "none")};

  &[aria-selected="true"] {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.4);
  }
`;

export const Placeholder = ({
  attributes,
  children,
  element,
}: ElementRendererProps<"placeholder">) => {
  const selected = useSelected();
  const { placeholders } = useEditorContext();

  return (
    <span {...attributes} contentEditable={false} title={element.name}>
      {children}
      <PlaceholderWrapper aria-selected={selected} isLink={element.isLink}>
        {placeholders?.data[element.name] ||
          element.displayText ||
          element.name}
      </PlaceholderWrapper>
    </span>
  );
};
