import { z } from "zod";

import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";
import { FieldNS } from "./field";
import { FormNS } from "./form";
import { SubmissionLinkHashNS } from "./hash";
import { SectionNS } from "./section";
import { TeamNS } from "./team";
import { UserNS } from "./user";

export const SubmissionNS = buildNamespace({
  system,
  entity: "Submission",
} as const);

export const submissionStatusValue = [
  "draft",
  "active",
  "completed",
  "cancelled",
] as const;
export const submissionSyncStatusValue = [
  "synced",
  "notSynced",
  "syncing",
  "failedToSync",
  "loading",
  "loaded",
  "failedToLoad",
  "pendingContactReview",
  "notToSync",
  "reviewed",
] as const;
export const submissionCommunicationMethodValue = [
  "email",
  "communicate",
  "embed",
  "internalUse",
  "document",
] as const;

export type SubmissionStatus = (typeof submissionStatusValue)[number];
export type SubmissionSyncStatus = (typeof submissionSyncStatusValue)[number];
export const submissionStatusLabel: Record<SubmissionStatus, string> = {
  draft: "Unshared",
  active: "Shared",
  completed: "Completed",
  cancelled: "Closed",
};
export const submissionExtendedLabel: Record<string, string> = {
  internalUse: "Internal use",
};
export const submissionStatusRank: Record<SubmissionStatus | "form", number> = {
  active: 3,
  completed: 2,
  cancelled: 1,
  draft: 0,
  form: 4,
};

export const submissionStatusSchema = z.enum(submissionStatusValue);
export const submissionSyncStatusSchema = z
  .enum(submissionSyncStatusValue)
  .optional();
export const submissionCommunicationMethodSchema = z
  .enum(submissionCommunicationMethodValue)
  .optional();
export const submissionRecipientSchema = z
  .object({
    contactId: z.string(),
    email: z.string(),
    contactName: z.string(),
  })
  .optional();

export const SubmissionSchema = z.object({
  uri: SubmissionNS.schema,
  operationId: z.string(),

  teamUri: TeamNS.schema,
  formUri: FormNS.schema,
  label: z.string().optional(),
  matterId: z.string().optional(),

  createdBy: UserNS.schema,
  createdAt: z.string(),
  updatedAt: z.string(),

  status: submissionStatusSchema,
  syncStatus: submissionSyncStatusSchema,

  lastUpdatedFieldUri: FieldNS.schema.optional(),
  lastUpdatedSectionUri: SectionNS.schema.optional(),

  communicationMethod: submissionCommunicationMethodSchema,
  recipientContactId: z.string().optional(),
  recipientEmail: z.string().optional(),
  recipient: submissionRecipientSchema,

  hashUri: SubmissionLinkHashNS.schema.optional(),

  deleted: z.boolean().optional(),
  deletedAtSec: z.number().optional(),
});

export const extractSubmissionUri = (link: string = "") => {
  const [id, slug] = link.split("/").reverse();
  if (!id) throw new Error(`No submission id in link - ${link}`);
  if (!slug) throw new Error(`No slug in link - ${link}`);

  return {
    id,
    slug,
    uri: SubmissionNS.generateUri(id),
  };
};
