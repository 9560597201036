export const uiKeys = [
  "orange",
  "blue",
  "red",
  "yellow",
  "green",
  "purple",
  "cyan",
] as const;

export type UIKey = (typeof uiKeys)[number];

export type UIShade = {
  r100: string;
  r80: string;
  r60: string;
  r40: string;
  r20: string;
  r10: string;
  r5: string;
};

export type GreyShade = {
  r100: string;
  r90: string;
  r80: string;
  r70: string;
  r60: string;
  r50: string;
  r40: string;
  r35: string;
  r30: string;
  r25: string;
  r20: string;
  r15: string;
  r10: string;
  r5: string;
  r0: string;
};

export type Scheme = { grey: GreyShade } & {
  [k in UIKey]: UIShade;
};

type TuplePath<T> = T extends string | number
  ? []
  : [keyof T, ...TuplePath<T[keyof T]>];

export type SchemeColor = TuplePath<Scheme>;

export const fromSchemeColor =
  (colour: SchemeColor | undefined, fallback: string) =>
  (props: { theme: { scheme: Scheme } }) =>
    typeof colour === "undefined"
      ? fallback
      : (props.theme.scheme[colour[0]] as GreyShade)[colour[1]];

export const scheme: Scheme = {
  grey: {
    r100: "rgba(28, 34, 48, 1)",
    r90: "rgba(42, 51, 72, 1)",
    r80: "rgba(56, 68, 96, 1)",
    r70: "rgba(84, 102, 144, 1)",
    r60: "rgba(112, 128, 162, 1)",
    r50: "rgba(141, 153, 181, 1)",
    r40: "rgba(169, 178, 199, 1)",
    r35: "rgba(198, 204, 218, 1)",
    r30: "rgba(221, 224, 233, 1)",
    r25: "rgba(232, 235, 240, 1)",
    r20: "rgba(240, 242, 245, 1)",
    r15: "rgba(243, 245, 247, 1)",
    r10: "rgba(247, 248, 250, 1)",
    r5: "rgba(250, 251, 252, 1)",
    r0: "rgba(255, 255, 255, 1)",
  },
  orange: {
    r100: "rgba(242, 96, 12, 1)",
    r80: "rgba(245, 128, 61, 1)",
    r60: "rgba(247, 160, 109, 1)",
    r40: "rgba(250, 191, 158, 1)",
    r20: "rgba(252, 223, 206, 1)",
    r10: "rgba(253, 239, 230, 1)",
    r5: "rgba(254, 250, 243, 1)",
  },
  blue: {
    r100: "rgba(26, 86, 219, 1)",
    r80: "rgba(72, 120, 226, 1)",
    r60: "rgba(118, 154, 233, 1)",
    r40: "rgba(163, 187, 241, 1)",
    r20: "rgba(209, 221, 248, 1)",
    r10: "rgba(232, 238, 251, 1)",
    r5: "rgba(244, 250, 253, 1)",
  },
  red: {
    r100: "rgba(230, 12, 25, 1)",
    r80: "rgba(235, 61, 71, 1)",
    r60: "rgba(240, 109, 117, 1)",
    r40: "rgba(245, 158, 163, 1)",
    r20: "rgba(250, 206, 209, 1)",
    r10: "rgba(252, 230, 232, 1)",
    r5: "rgba(254, 243, 244, 1)",
  },
  yellow: {
    r100: "rgba(230, 203, 0, 1)",
    r80: "rgba(240, 217, 39, 1)",
    r60: "rgba(245, 226, 86, 1)",
    r40: "rgba(249, 238, 154, 1)",
    r20: "rgba(251, 243, 187, 1)",
    r10: "rgba(253, 249, 221, 1)",
    r5: "rgba(254, 252, 238, 1)",
  },
  green: {
    r100: "rgba(60, 186, 134, 1)",
    r80: "rgba(75, 233, 167, 1)",
    r60: "rgba(147, 242, 202, 1)",
    r40: "rgba(183, 246, 220, 1)",
    r20: "rgba(219, 251, 237, 1)",
    r10: "rgba(237, 253, 246, 1)",
    r5: "rgb(246, 254, 251, 1)",
  },
  purple: {
    r100: "rgba(127, 86, 217, 1)",
    r80: "rgba(153, 120, 225, 1)",
    r60: "rgba(178, 154, 232, 1)",
    r40: "rgba(204, 187, 240, 1)",
    r20: "rgba(229, 221, 247, 1)",
    r10: "rgba(242, 238, 251, 1)",
    r5: "rgba(249, 247, 253, 1)",
  },
  cyan: {
    r100: "rgba(44, 179, 203, 1)",
    r80: "rgba(106, 208, 226, 1)",
    r60: "rgba(128, 216, 231, 1)",
    r40: "rgba(194, 237, 245, 1)",
    r20: "rgba(204, 240, 247, 1)",
    r10: "rgba(235, 249, 252, 1)",
    r5: "rgba(243, 251, 253, 1)",
  },
};
