import { Country, auStates, ukStates, usStates } from "./country";
import { formatInTimeZone, fromZonedTime } from "date-fns-tz";

type Verb = {
  past: string;
  present: string;
  future: string;
};

type Term = {
  singular: string;
  plural: string;
  title: string;
  header: string;
  name: string;
};

export type Locale = Readonly<{
  country: Country;
  locale: "en-AU" | "en-US" | "en-GB";
  currency: "AUD" | "USD" | "GBP";
  dateFormat: ("day" | "month" | "year")[];
  states: { name: string; displayName: string }[];
  terms: {
    digitise: Verb;
    practise: Verb;
    state: Term;
    familyPro: Term;
  };
}>;

export const auLocale: Locale = {
  country: "AU",
  locale: "en-AU",
  currency: "AUD",
  states: auStates.map((state) => ({
    name: state.name,
    displayName: state.name,
  })),
  dateFormat: ["day", "month", "year"],
  terms: {
    digitise: {
      past: "digitised",
      present: "digitising",
      future: "digitise",
    },
    practise: {
      past: "practised",
      present: "practising",
      future: "practise",
    },
    state: {
      singular: "a state",
      plural: "states",
      title: "States",
      header: "State",
      name: "state",
    },
    familyPro: {
      singular: "FamilyProperty",
      plural: "FamilyProperty",
      title: "FamilyProperty",
      header: "FamilyProperty",
      name: "FamilyProperty",
    },
  },
};

export const usLocale: Locale = {
  country: "US",
  locale: "en-US",
  currency: "USD",
  states: usStates.map((state) => ({
    name: state.name,
    displayName: state.name,
  })),
  dateFormat: ["month", "day", "year"],
  terms: {
    digitise: {
      past: "digitized",
      present: "digitizing",
      future: "digitize",
    },
    practise: {
      past: "practiced",
      present: "practicing",
      future: "practice",
    },
    state: {
      singular: "a state",
      plural: "states",
      title: "States",
      header: "State",
      name: "state",
    },
    familyPro: {
      singular: "FamilyProperty",
      plural: "FamilyProperty",
      title: "FamilyProperty",
      header: "FamilyProperty",
      name: "FamilyProperty",
    },
  },
};

export const ukLocale: Locale = {
  country: "GB",
  locale: "en-GB",
  currency: "GBP",
  states: ukStates.map((state) => ({
    name: state.name,
    displayName: state.label,
  })),
  dateFormat: ["day", "month", "year"],
  terms: {
    digitise: {
      past: "digitised",
      present: "digitising",
      future: "digitise",
    },
    practise: {
      past: "practised",
      present: "practising",
      future: "practise",
    },
    state: {
      singular: "a country",
      plural: "countries",
      title: "Countries",
      header: "Country",
      name: "country",
    },
    familyPro: {
      singular: "FamilyProperty",
      plural: "FamilyProperty",
      title: "FamilyProperty",
      header: "FamilyProperty",
      name: "FamilyProperty",
    },
  },
};

export const convertToLocalTime = ({
  startTime,
  endTime,
  timezone,
}: {
  startTime: string;
  endTime: string;
  timezone?: string | null;
}) => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZone = timezone || localTimeZone;

  const createZonedDate = (dateString: string, timeZone: string) => {
    const date = new Date(dateString);
    return fromZonedTime(date, timeZone);
  };

  const startTimeInTimeZone = createZonedDate(startTime, timeZone);
  const endTimeInTimeZone = createZonedDate(endTime, timeZone);

  const startTimeLocalFormatted = formatInTimeZone(
    startTimeInTimeZone,
    localTimeZone,
    "yyyy-MM-dd'T'HH:mm:ss",
  );
  const endTimeLocalFormatted = formatInTimeZone(
    endTimeInTimeZone,
    localTimeZone,
    "yyyy-MM-dd'T'HH:mm:ss",
  );

  return {
    startTimeLocal: startTimeLocalFormatted,
    endTimeLocal: endTimeLocalFormatted,
  };
};
