import {
  ForwardedRef,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
} from "react";

import { Icon, IconDetail } from "@smart/itops-icons-dom";

import { Input, InputKind, InputWrapper } from "./wrapper";
import { FontSizeKey, SchemeColor } from "../../theme";

export type TextInputProps = {
  size?: FontSizeKey;
  kind?: InputKind;
  icon?: IconDetail & { schemeColor?: SchemeColor };
  right?: ReactNode;
  error?: boolean;
  className?: string;
} & Pick<
  InputHTMLAttributes<HTMLInputElement>,
  | "id"
  | "disabled"
  | "name"
  | "title"
  | "type"
  | "placeholder"
  | "value"
  | "defaultValue"
  | "onChange"
  | "onBlur"
  | "onFocus"
  | "onKeyDown"
  | "readOnly"
>;

const BaseTextInput = (
  {
    id,
    size,
    kind,
    icon,
    right,
    error,
    disabled,
    className,
    ...inputProps
  }: TextInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => (
  <InputWrapper
    kind={kind}
    size={size}
    className={className}
    aria-disabled={disabled}
    iconColor={icon?.schemeColor}
  >
    {icon && <Icon className="input-icon" size={18} {...icon} />}
    <Input
      id={id}
      disabled={disabled}
      ref={ref}
      aria-invalid={error}
      aria-errormessage={error ? `${id}-error` : undefined}
      {...inputProps}
    />
    {right}
  </InputWrapper>
);

export const TextInput = forwardRef(BaseTextInput);
