import { z } from "zod";

import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";
import { FormNS } from "./form";
import { fieldLinkSchema } from "./link";

export const SectionNS = buildNamespace({
  system,
  entity: "Section",
} as const);

export const SectionSchema = z.object({
  uri: SectionNS.schema,
  operationId: z.string(),

  formUri: FormNS.schema,
  order: z.string().regex(/[a-z:]/),

  title: z.string(),
  description: z.string().optional(),

  updatedAt: z.string(),
  deleted: z.boolean().optional(),
  deletedAtSec: z.number().optional(),

  links: z.array(fieldLinkSchema).optional(),
});
