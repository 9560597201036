import styled from "@emotion/styled";
import { position } from "polished";

export const TabContainer = styled.div`
  padding: 0.5rem;
  border-radius: 6rem;
  background-color: ${(props) => props.theme.scheme.grey.r20};
  display: flex;
  gap: 0.5rem;
  position: relative;

  &[aria-disabled="true"] {
    opacity: 0.8;

    &:after {
      content: "";
      ${position("absolute", 0)};
      cursor: wait;
    }
  }

  button {
    border: 0;
    background-color: transparent;
    border-radius: 4rem;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0);
    color: ${(props) => props.theme.scheme.grey.r60};
    cursor: pointer;
    font-size: ${(props) => props.theme.fontSize.base};
    display: block;
    font-weight: 600;
    min-width: 12rem;
    padding: 1.3rem 1.5rem;
    transition:
      background 0.3s ease,
      box-shadow 0.3s ease,
      color 0.3s ease;

    &[aria-pressed="true"] {
      background: ${(props) => props.theme.scheme.grey.r0};
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
      color: ${(props) => props.theme.scheme.blue.r100};
    }
  }
`;

export type TabsProps<T extends string> = {
  tabs: T[];
  labels?: Partial<Record<T, string>>;
  tab: T | undefined;
  selectTab: (tab: T) => void;
  disabled?: boolean;
};

export const Tabs = <T extends string>({
  tabs,
  labels,
  tab,
  selectTab,
  disabled,
}: TabsProps<T>) => (
  <TabContainer aria-disabled={disabled}>
    {tabs.map((t) => (
      <button
        key={t}
        type="button"
        aria-pressed={t === tab}
        onClick={() => selectTab(t)}
      >
        {labels?.[t] || t}
      </button>
    ))}
  </TabContainer>
);
