import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { isKeyOf } from "@smart/itops-utils-basic";

import { fileTypes } from "./file-types";
import { UploadFileProps, UploadFn, UploadStatus } from "./types";
import { Button } from "../button";
import { FailureMessage } from "../failure-message";
import { Spinner } from "../spinner";

const FileFieldItemWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: ${(props) => props.theme.baseUnit}rem;
  margin: ${(props) => props.theme.baseUnit}rem 0;

  .file-type {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: ${(props) => props.theme.baseUnit * 2.8}rem;
    height: ${(props) => props.theme.baseUnit * 2.8}rem;
    margin-right: ${(props) => props.theme.baseUnit * 0.3}rem;

    flex: 0 0 ${(props) => props.theme.baseUnit * 2.8}rem;
  }

  .details {
    position: relative;
    min-width: 0;

    p {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;

      a:not(:disabled) {
        color: ${(props) => props.theme.scheme.blue.r100};
        text-decoration: underline;
      }
    }

    .progress {
      position: absolute;
      bottom: -${(props) => props.theme.baseUnit * 0.5}rem;
      left: 0;
      right: 0;
      height: ${(props) => props.theme.baseUnit * 0.4}rem;

      background: ${(props) => props.theme.scheme.grey.r30};
      border-radius: 1rem;
      overflow: hidden;

      .bar {
        height: 100%;
        background: ${(props) => props.theme.scheme.blue.r100};
        opacity: 0.6;

        transition: width 0.2s ease;
      }
    }
  }

  button {
    margin: 0;
  }
`;

export type FileFieldItemProps = {
  fileName: string;
  downloadUrl: string | undefined;
  uploadUrl: string | undefined;
  file: File | undefined;
  uploadStatus: UploadStatus;
  upload: UploadFn;
  onStatusChange: (uploadStatus: UploadStatus) => void;
  onRemove: () => void;
  uploadFileProps: UploadFileProps;
  fileSizeLimitMegaByte: number;
  maxNumOfFiles: number;
  fileIndex: number;
  fieldId: string;
};

const knownFileType = isKeyOf(fileTypes);
const fileNameToIcon = (name: string) => {
  const extension = name.split(".").reverse()[0];
  return knownFileType(extension) ? fileTypes[extension] : fileTypes.txt;
};

export const FileFieldItem = ({
  fileName,
  downloadUrl,
  uploadUrl,
  file,
  uploadStatus,
  upload,
  onStatusChange,
  onRemove,
  fileSizeLimitMegaByte,
  maxNumOfFiles,
  fileIndex,
  fieldId: id,
  uploadFileProps: {
    onProgress,
    onUploadStatusChange,
    initialise,
    getUploadStates,
  },
}: FileFieldItemProps) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const uploadStates = getUploadStates(id);

  useEffect(() => {
    if (uploadStates.length > 0) {
      setUploading(uploadStates[fileIndex].uploading);
      setProgress(uploadStates[fileIndex].progress);
    } else {
      initialise(id, maxNumOfFiles);
    }

    if (progress === 100 && uploadStatus === "uploading") {
      onUploadStatusChange(id, fileIndex, false);
      onStatusChange("uploaded");
    }
  }, [uploadStates, progress, uploading]);

  useEffect(() => {
    if (uploadStatus === "notUploaded" && uploadUrl && file && !uploading) {
      onUploadStatusChange(id, fileIndex, true);
      onStatusChange("uploading");
      setUploading(true);

      upload({
        uploadUrl,
        file,
        onProgress: (newProgress) => {
          onProgress(id, fileIndex, newProgress);
          setProgress(newProgress);
        },
      })
        .then(() => {
          onUploadStatusChange(id, fileIndex, false);
          onStatusChange("uploaded");
          setUploading(false);
        })
        .catch((e) => {
          console.error(e);
          onUploadStatusChange(id, fileIndex, false);
          onStatusChange("failedToUpload");
          setUploading(false);
        });
    }
  }, [uploadStatus, uploadUrl]);

  if (
    ["failedToUpload", "exceedSizeLimit", "unsupportedFileType"].includes(
      uploadStatus,
    )
  ) {
    let message;
    switch (uploadStatus) {
      case "exceedSizeLimit":
        message = `"${fileName}" is too big and could not be uploaded. Max file size is ${fileSizeLimitMegaByte} MB.`;
        break;
      case "unsupportedFileType":
        message = `"${fileName}" file type is not supported.`;
        break;
      default:
        message = `"${fileName}" could not be uploaded. Please try to upload it again.`;
        break;
    }

    return (
      <FileFieldItemWrapper className="file-field-item">
        <FailureMessage textOverride={message} action="" />
        <Button
          icon={{ library: "lucide", name: "X" }}
          title="Remove File"
          onClick={onRemove}
        />
      </FileFieldItemWrapper>
    );
  }

  return (
    <FileFieldItemWrapper className="file-field-item">
      {uploading || uploadStatus === "notUploaded" ? (
        <Spinner size={3} />
      ) : (
        <div
          className="file-type"
          style={{ backgroundImage: `url(${fileNameToIcon(fileName)})` }}
        />
      )}
      <div className="details">
        <p>
          {uploading || !downloadUrl ? (
            fileName
          ) : (
            <a href={downloadUrl} target="_blank" rel="noreferrer">
              {fileName}
            </a>
          )}
        </p>
        {uploading && (
          <div
            className="progress"
            role="progressbar"
            aria-label="Upload Progress"
            aria-valuenow={progress}
          >
            <div className="bar" style={{ width: `${progress}%` }} />
          </div>
        )}
      </div>
      {uploadStatus === "uploaded" && (
        <Button
          icon={{ library: "lucide", name: "Trash2" }}
          kind="borderless"
          title="Remove File"
          variant="remove"
          onClick={onRemove}
        />
      )}
    </FileFieldItemWrapper>
  );
};
