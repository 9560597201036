import { PlaceholderDetails } from "@smart/itops-serialisation-basic";

const basePlaceholders = ["firmName", "firmPhone", "matterCategory"] as const;

const responsePlaceholders = ["downloadFormLink"] as const;

const messagePlaceholders = ["clientName", "formLink", "matterType"] as const;

export const templateMessagePlaceholders = [
  ...basePlaceholders,
  ...messagePlaceholders,
];

export type TemplateMessageData = Record<
  (typeof templateMessagePlaceholders)[number],
  string
>;

export const templateResponsePlaceholders = [
  ...basePlaceholders,
  ...responsePlaceholders,
];

export type TemplateResponseData = Record<
  (typeof templateResponsePlaceholders)[number],
  string
>;

export const templateAllPlaceholders = [
  ...basePlaceholders,
  ...responsePlaceholders,
  ...messagePlaceholders,
];

export type TemplatePlaceholders = (typeof templateAllPlaceholders)[number];

export const templatePlaceholderDetails: PlaceholderDetails<TemplatePlaceholders> =
  {
    firmName: { label: "Firm Name" },
    firmPhone: { label: "Firm Phone" },
    matterCategory: { label: "Matter Category" },
    downloadFormLink: {
      label: "Download Form Link",
      isLink: true,
      defaultValue: "Click here to save a copy of your form",
    },
    clientName: { label: "Client Name" },
    formLink: { label: "Form Link", isLink: true, defaultValue: "Open Form" },
    matterType: { label: "Matter Type" },
  };
