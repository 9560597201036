import styled from "@emotion/styled";
import { useState } from "react";

import { Icon } from "@smart/itops-icons-dom";

import { Button } from "../button";

const FileUploadWrapper = styled.label<{ dragging: boolean }>`
  margin: ${(props) => props.theme.baseUnit}rem 0;
  background: ${(props) =>
    props.dragging ? props.theme.scheme.blue.r10 : props.theme.scheme.grey.r10};
  border: 2px dashed
    ${(props) =>
      props.dragging
        ? props.theme.scheme.blue.r100
        : props.theme.scheme.grey.r40};
  border-radius: 10px;
  color: ${(props) => props.theme.scheme.grey.r80};
  outline: none;

  cursor: pointer;
  font-weight: 500;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: ${(props) => props.theme.baseUnit * 2}rem;

  .file-upload-icon {
    margin: ${(props) => props.theme.baseUnit * 0.6}rem 0;
  }

  p {
    font-size: ${(props) => props.theme.fontSize.base};
    margin: ${(props) => props.theme.baseUnit * 0.1}rem 0;
  }

  button {
    margin: ${(props) => props.theme.baseUnit * 2}rem 0 0;
    pointer-events: none;
  }

  .size-limit {
    font-size: ${(props) => props.theme.fontSize.small};
    color: ${(props) => props.theme.scheme.grey.r70};
  }

  &[aria-invalid="true"] {
    border-color: ${(props) => props.theme.scheme.red.r100};
  }

  &:focus,
  &:focus-within {
    border-color: ${(props) => props.theme.scheme.blue.r100};
  }
`;

export const FileInputField = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  height: 0;
  width: 0;
  opacity: 0;
  overflow: hidden;
  outline: none;
`;

type FileUploadProps = {
  id: string;
  errorId: string;
  error?: string;
  sizeLimit: string;
  onSelect: (files?: FileList) => void;
};

export const FileUpload = ({
  id,
  error,
  errorId,
  sizeLimit,
  onSelect,
}: FileUploadProps) => {
  const [dragging, setDragging] = useState(false);

  return (
    <FileUploadWrapper
      htmlFor={id}
      dragging={dragging}
      aria-invalid={!!error}
      aria-errormessage={error ? errorId : undefined}
      onDragOver={(e) => {
        e.preventDefault();
        setDragging(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setDragging(false);
      }}
      onDrop={(e) => {
        e.preventDefault();
        setDragging(false);
        onSelect(e.dataTransfer.files);
      }}
      className="file-upload-wrapper"
    >
      <Icon
        className="file-upload-icon"
        library="lucide"
        name="HardDriveUpload"
      />
      <p>Click to upload or drag and drop</p>
      <p className="size-limit">{sizeLimit}</p>
      <Button
        size="small"
        variant="cancel"
        icon={{ library: "lucide", name: "Search", size: 16 }}
        text="Browse files"
      />
    </FileUploadWrapper>
  );
};
