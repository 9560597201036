import styled from "@emotion/styled";

import { FontSizeKey, SchemeColor, fromSchemeColor } from "../../theme";

const standard = styled.span<{
  size?: FontSizeKey;
  iconColor?: SchemeColor;
}>`
  --background: ${(props) => props.theme.scheme.grey.r0};
  background: var(--background);
  border: 1px solid ${(props) => props.theme.scheme.grey.r35};
  border-radius: 0.8rem;
  font-size: ${(props) =>
    props.size ? props.theme.fontSize[props.size] : "inherit"};
  padding: 0 0.4rem;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    width: 0;
    height: 4rem;
  }

  &:hover,
  &:focus-within {
    border-color: ${(props) => props.theme.scheme.blue.r60};
  }

  &:focus-within {
    box-shadow: 0 0 0 3px ${(props) => props.theme.scheme.blue.r10};
  }

  &:has([aria-invalid="true"]) {
    border-color: ${(props) => props.theme.scheme.red.r60};
    box-shadow: 0 0 0 3px ${(props) => props.theme.scheme.red.r10};
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
    --background: ${(props) => props.theme.scheme.grey.r15};
    border-color: ${(props) => props.theme.scheme.grey.r35};

    input {
      cursor: not-allowed;
    }
  }

  .input-icon {
    margin: 0 0rem 0 0.6rem;
    color: ${(props) =>
      fromSchemeColor(props.iconColor, props.theme.scheme.grey.r50)(props)};
  }
`;

export const inputKinds = {
  standard,
  round: styled(standard)`
    border-radius: 100px;
    padding: 0 0.8rem;
  `,
} satisfies Record<string, typeof standard>;

export type InputKind = keyof typeof inputKinds;

export const InputWrapper = (
  props: Parameters<typeof standard>[0] & {
    kind?: InputKind;
  },
) => {
  const Styled = (props.kind && inputKinds[props.kind]) || standard;
  return <Styled {...props} />;
};

export const Input = styled.input`
  color: inherit;
  font-size: inherit;
  flex: 1;
  border: 0;
  padding: 1rem 1.2rem;
  background: none;
  outline: none;
  width: 8rem;
`;
