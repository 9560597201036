import styled from "@emotion/styled";
import {
  ForwardedRef,
  TextareaHTMLAttributes,
  ReactNode,
  forwardRef,
} from "react";

import { InputKind, InputWrapper } from "./wrapper";
import { FontSizeKey } from "../../theme";

const Area = styled.textarea`
  color: inherit;
  font-size: inherit;
  flex: 1;
  border: 0;
  padding: 1rem 1.2rem;
  resize: none;
  background: none;
  outline: none;
  width: 8rem;
`;

export type TextAreaProps = {
  size?: FontSizeKey;
  kind?: InputKind;
  right?: ReactNode;
  error?: boolean;
  className?: string;
} & Pick<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  | "id"
  | "disabled"
  | "name"
  | "title"
  | "placeholder"
  | "rows"
  | "value"
  | "defaultValue"
  | "onChange"
  | "onBlur"
>;

const BaseTextArea = (
  {
    id,
    size,
    kind,
    right,
    error,
    disabled,
    className,
    ...inputProps
  }: TextAreaProps,
  ref: ForwardedRef<HTMLTextAreaElement>,
) => (
  <InputWrapper
    kind={kind}
    size={size}
    className={className}
    aria-disabled={disabled}
  >
    <Area
      id={id}
      disabled={disabled}
      ref={ref}
      aria-invalid={error}
      aria-errormessage={error ? `${id}-error` : undefined}
      {...inputProps}
    />
    {right}
  </InputWrapper>
);

export const TextArea = forwardRef(BaseTextArea);
